<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Bloques</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Bloques</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Bloques</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Sitios"
                    data-toggle="tab"
                    href="#Sitios"
                    v-if="id != 0"
                    >Sitios</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Geocercas"
                    data-toggle="tab"
                    href="#Geocercas"
                    @click="$refs.BloqueGeocerca.init()"
                    v-if="form.id"
                    >Geocercas</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="nombre">Nombre Bloque</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nombre"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="codigo">Código</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="codigo"
                            v-model="form.codigo"
                          />
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <label for="direccion">Dirección</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="direccion"
                            v-model="form.direccion"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="departamento">Departamento</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.departamento_id"
                            id="departamento"
                            :class="
                              $v.form.departamento_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @change="getCiudades()"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="departamento in objLista.departamentos"
                              :key="departamento.id"
                              :value="departamento.id"
                            >
                              {{ departamento.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="ciudad">Ciudad</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.ciudad_id"
                            id="ciudad"
                            :class="
                              $v.form.ciudad_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="ciudad in objLista.ciudades"
                              :key="ciudad.id"
                              :value="ciudad.id"
                            >
                              {{ ciudad.nombre }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="prefijo">Prefijo</label>
                          <input
                            type="text"
                            maxlength="3"
                            class="form-control form-control-sm"
                            id="prefijo"
                            v-model="form.prefijo"
                            :class="
                              $v.form.prefijo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="estado">Estado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            disabled
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in objLista.estados"
                              :key="estado.id"
                              :value="estado.id"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Justificación Inactivación</label>
                          <textarea
                            class="form-control form-control-sm"
                            v-model="form.justificacion"
                            disabled
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Sitios" v-if="id != 0">
                  <div class="card-body">
                    <!-- Inicio del formulario -->
                    <div class="row" v-show="!$v.form.$invalid">
                      <div
                        class="card card-secondary card-outline container-fluid col-md-6"
                      >
                        <div class="card-header">
                          <h3 class="card-title">
                            <i class="fas fa-map"></i>
                            Administrar Sitios
                          </h3>
                        </div>
                        <div class="card-body">
                          <div
                            class="row"
                            v-if="
                              objLista.sitios.length > 0 &&
                                form.estado == 1 &&
                                $store.getters.can('admin.bloques.edit')
                            "
                          >
                            <div class="col-md-10">
                              <div class="form-group">
                                <label for="sitio">Sitio</label>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="formSitios.id"
                                  :class="
                                    $v.formSitios.id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="sitio in objLista.sitios"
                                    :key="sitio.id"
                                    :value="sitio.id"
                                  >
                                    {{ sitio.nombre }}
                                  </option>
                                </select>
                                <div
                                  class="error"
                                  v-if="!$v.formSitios.id.required"
                                >
                                  Seleccione un sitio
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <br />
                              <button
                                class="btn bg-primary col-md-12 mt-1"
                                v-show="!$v.formSitios.$invalid"
                                v-if="$store.getters.can('admin.bloques.edit')"
                                @click="addSitio()"
                              >
                                <i class="fas fa-save"></i><br />
                              </button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="card-body p-0">
                              <table
                                id="sitios"
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <th>Sitio</th>
                                    <th style="width: 25px">Acción</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="data in sitios" :key="data.id">
                                    <td v-text="data.nombre"></td>
                                    <td class="text-right">
                                      <button
                                        type="button"
                                        class="btn btn-danger btn-sm"
                                        v-if="
                                          $store.getters.can(
                                            'admin.bloques.delete'
                                          ) && form.estado != 2
                                        "
                                        @click="removeSitio(data.id)"
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Fin del formulario -->
                  </div>
                </div>
                <div class="tab-pane fade" id="Geocercas" v-if="form.id">
                  <div class="card-body">
                    <BloqueGeocerca ref="BloqueGeocerca" v-if="form.id" />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can('admin.bloques.create') ||
                        $store.getters.can('admin.bloques.edit')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import BloqueGeocerca from "./BloqueGeocerca.vue";

export default {
  name: "BloqueForm",
  components: {
    Loading,
    BloqueGeocerca,
  },
  data() {
    return {
      form: {},
      formSitios: {},
      accion: "",
      metodo: "",
      sitios: {},
      objLista: {
        estados: [],
        departamentos: [],
        ciudades: [],
        sitios: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      id: this.$route.params.id,
      cargando: false,
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      departamento_id: {
        required,
      },
      ciudad_id: {
        required,
      },
      prefijo: {
        required,
      },
    },
    formSitios: {
      id: {
        required,
      },
    },
  },
  methods: {
    getDepartamentos() {
      axios.get("/api/admin/departamentos/lista").then((response) => {
        this.objLista.departamentos = response.data;
      });
    },

    getCiudades() {
      axios
        .get("/api/admin/ciudades/lista", {
          params: {
            departamento: this.form.departamento_id,
          },
        })
        .then((response) => {
          this.objLista.ciudades = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.objLista.estados = response.data;
      });
    },

    getSitios() {
      axios
        .get("/api/admin/sitios/lista", {
          params: { bloque_id: 0 },
        })
        .then((response) => {
          this.objLista.sitios = response.data;
        });
    },

    getSitiosBloque() {
      axios
        .get("/api/admin/sitios/lista", {
          params: { bloque_id: this.id },
        })
        .then((response) => {
          this.sitios = response.data;
        });
    },

    // Función para asignar el sitio al bloque
    addSitio() {
      this.formSitios.bloque_id = this.form.id;
      if (!this.$v.formSitios.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/sitios",
          data: this.formSitios,
        })
          .then(() => {
            this.formSitios = {};
            this.init();
            this.$swal({
              icon: "success",
              title: "Se asignó el sitio exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    // Función para quitar el sitio al bloque
    removeSitio(id) {
      const params = {
        id,
        bloque_id: 0,
      };
      this.$swal({
        title: "Esta seguro de retirar este Sitio?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios({
            method: this.metodo,
            url: "/api/admin/sitios",
            data: params,
          }).then((response) => {
            this.init();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
        }
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/bloques",
          data: this.form,
        })
          .then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Los datos se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return this.$router.push({
              name: "/Admin/Bloques",
              params: { msg: "El Bloque se guardó exitosamente..." },
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/Bloques");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.getCiudades();
        this.getSitios();
        this.getSitiosBloque();
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
      }
    },
  },
  async mounted() {
    await this.init();
    await this.getEstados();
    await this.getDepartamentos();
  },
};
</script>
